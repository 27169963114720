'use client';

import React, { useEffect, useState } from 'react';
import Title from '../HighlightedNews/Title';

export default function FormNews() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [formValid, setFormValid] = useState(false);

  useEffect(() => {
    const isValid = name && email;
    setFormValid(isValid);
  }, [name, email]);

  const sendData = async (e) => {
    e.preventDefault();

    if (!recaptchaToken) {
      try {
        const token = await recaptchaRef.current.executeAsync();
        setRecaptchaToken(token);
        recaptchaRef.current.reset();
      } catch (error) {
        alert('Erro ao verificar reCAPTCHA. Tente novamente.');
        console.error(error);
        return;
      }
    }

    const content = {
      nameFrom: `${name} | Receber conteúdos do Blog Valorizza`,
      from: email,
      to: process.env.NEXT_PUBLIC_CLIENT_BLOG_EMAIL_ADDRESS,
      subject: 'Desejo receber conteúdos da Valorizza no e-mail.',
      text: `Nome: ${name}\nEmail: ${email}`,
    };

    try {
      const res = await fetch(process.env.NEXT_PUBLIC_EMAIL_URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(content),
      });

      const data = await res.json();
      if (data) {
        alert('Email enviado com sucesso!');
        setName('');
        setEmail('');
      }
    } catch (error) {
      alert('Erro ao enviar o email. Tente novamente mais tarde.');
      console.error(error);
    }
  };

  return (
    <div className="flex flex-col gap-6">
      <Title title="Valorizza News" font="text-lg" />
      <div className="w-full border-2 border-customColorGreen200 rounded-lg p-4 lg:p-6 flex flex-col gap-4">
        <input
          type="text"
          placeholder="Nome"
          value={name}
          onChange={(e) => setName(e.target.value)}
          className="w-full rounded-lg h-[40px] text-customColorWhite placeholder:text-customColorWhite bg-gradient-to-r from-customColorGrayBorder to-customColorBlackBg focus:outline-none focus:border-none focus:ring-2 focus:ring-customColorGreen200"
        />
        <input
          type="text"
          placeholder="E-mail"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className="w-full rounded-lg h-[40px] text-customColorWhite placeholder:text-customColorWhite bg-gradient-to-r from-customColorGrayBorder to-customColorBlackBg focus:outline-none focus:border-none focus:ring-2 focus:ring-customColorGreen200"
        />
        <button
          onClick={sendData}
          className={`${
            !formValid ? 'cursor-not-allowed' : ''
          } border-[1px] border-customColorWhite/50 text-customColorWhite button-gradient rounded-lg hover:border-customColorGreen200 h-[40px] flex items-center justify-center transition duration-300 w-full`}
          disabled={!formValid}
        >
          Receber conteúdos
        </button>
      </div>
    </div>
  );
}
